/* <input-star></input-star> directive */
.angular-input-stars {
	
	display: inline-block;
	padding: 0;
	padding-left: -5px;
	list-style: none;

}

.angular-input-stars > li {
		
	font-size: 17px;

	display: inline-block;
	padding-right: 5px;
	padding-left: 5px;
	
	cursor: pointer;	

}

.angular-input-stars > li .active {

		color: #FDE35E;
		text-shadow: black 0 0 1px, 0 0 1px ;	

}

.angular-input-stars > li .active.angular-input-stars-hover {
	text-shadow: black 0 0 3px;
}